import React from "react";

function MainSvg() {
  return (
    <div>
      <svg
        id="ba65d029-7b6e-4d16-9739-50ea7099b679"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="300"
        height="400"
        viewBox="0 0 888 618.10603"
      >
        <path
          d="M897.95656,235.99009l-68.50224.66737c-3.21977-15.85137-14.03811-57.44279-4.29525-68.78728A16.67423,16.67423,0,0,1,842.723,152.94961h0c10.61742-16.61983,28.30223-14.12446,40.255-3.74465,12.02932,4.15075,16.56454,11.52481,14.9786,21.53174,8.90863,7.798,10.55217,17.28535,6.55313,28.08487Z"
          transform="translate(-156 -140.94698)"
          fill="#2f2e41"
        />
        <path
          d="M891.36207,278.18922l-49.42241-6.8431-9.88449-14.44655c13.61613-9.03186,18.43428-21.64693,12.16552-38.77759l34.21552-.76034c-1.60321,15.25509,3.4416,27.06668,17.48793,34.21552Z"
          transform="translate(-156 -140.94698)"
          fill="#ffb9b9"
        />
        <path
          d="M830.255,723.96651l-1.88979,25.835a8.22428,8.22428,0,0,0,8.69988,8.80928L850.335,757.807a4.19488,4.19488,0,0,0,3.81529-5.2044l-7.36591-29.46277Z"
          transform="translate(-156 -140.94698)"
          fill="#e6e6e6"
        />
        <path
          d="M873.21559,723.13984l-7.36591,29.46277a4.19488,4.19488,0,0,0,3.81529,5.2044l13.26994.80373a8.22428,8.22428,0,0,0,8.69988-8.80928l-1.88979-25.835Z"
          transform="translate(-156 -140.94698)"
          fill="#e6e6e6"
        />
        <polygon
          points="734.602 586.606 716.694 584.809 698.866 394.239 692.128 584.195 674.534 583.565 644.881 320.485 758.172 320.485 734.602 586.606"
          fill="#311b92"
        />
        <circle cx="707.22931" cy="55.125" r="34.21552" fill="#ffb9b9" />
        <path
          d="M930.9,467.51509l-138.38276,1.52069c20.53674-78.18094,32.82143-150.80436,11.40517-200.731l34.21552-16.72758c16.49787,17.20783,34.87135,15.43378,54.74483-1.52069l32.69483,8.36379C919.97784,323.89141,922.48766,394.0114,930.9,467.51509Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M754.5,386.91853l85.919,34.21552,19.20933,5.6498a6.645,6.645,0,0,0,8.32164-4.76336h0a6.645,6.645,0,0,0-3.78319-7.69957l-19.94607-6.11272-60.06724-34.97586,12.54569-33.075L770.46724,326.091Z"
          transform="translate(-156 -140.94698)"
          fill="#ffb9b9"
        />
        <path
          d="M755.26034,324.57026c16.34695,6.37738,29.34874,17.26137,39.53794,31.93448l31.93448-46.381-22.81035-41.819Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M901.792,190.52671c-13.88705-3.57812-27.54045-4.06939-40.81285.46912-8.45455,4.09479-18.60778,3.7175-29.89922.34366V157.20263l60.95868,1.62557Z"
          transform="translate(-156 -140.94698)"
          fill="#2f2e41"
        />
        <path
          d="M840.13379,347.053A10.14514,10.14514,0,0,0,830,357.18681l4,83.73242A10.14514,10.14514,0,0,0,844.13379,451.053h45.73242A10.14514,10.14514,0,0,0,900,440.91923l4-83.73242A10.14514,10.14514,0,0,0,893.86621,347.053Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M969.89,380.593,895,410.413l-5.65,2.25c-.18.07-.36.13-.55.19l-18.93,5.57c-2.42.71-5.77-2.55-7.73-4.8a6.08507,6.08507,0,0,1-.16-7.93,6.74613,6.74613,0,0,1,.86005-.83,5.58864,5.58864,0,0,1,2.5-1.1l18.91-3.72a6.336,6.336,0,0,0,1.93-.72l8.82-5.14,45.79-26.66a6.21845,6.21845,0,0,0,2.69-7.58l-8.74-23.05a6.21114,6.21114,0,0,1,2.87-7.68l14.48-7.76a6.20589,6.20589,0,0,1,8.94,3.9L973.6,373.233A6.21936,6.21936,0,0,1,969.89,380.593Z"
          transform="translate(-156 -140.94698)"
          fill="#ffb9b9"
        />
        <path
          d="M974.23966,316.20647c-16.34695,6.37737-29.34874,17.26136-39.53794,31.93448l-31.93448-46.381,22.81035-43.33965Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M886,226.52555c-14.96826,6.22617-30.66333,5.97335-47,0,4.75366-8.114-2.352-14.46886-6-22.65975l61-1.81278C889.33521,209.16865,882.452,217.57561,886,226.52555Z"
          transform="translate(-156 -140.94698)"
          fill="#fff"
        />
        <polygon
          points="737.354 62.46 736.646 61.753 737.646 60.753 743.62 53.781 744.38 54.431 738.38 61.431 737.354 62.46"
          fill="#fff"
        />
        <rect
          x="830.99973"
          y="198.1481"
          width="1.00054"
          height="7.81025"
          transform="translate(-12.03491 570.53091) rotate(-50.19443)"
          fill="#fff"
        />
        <rect
          x="880.4712"
          y="217.30312"
          width="19.55761"
          height="1.0002"
          transform="matrix(0.537, -0.84358, 0.84358, 0.537, 72.44944, 710.89356)"
          fill="#fff"
        />
        <rect
          x="834.2499"
          y="208.02442"
          width="1.0002"
          height="19.55761"
          transform="translate(-142.42964 341.26087) rotate(-32.47119)"
          fill="#fff"
        />
        <path
          d="M897,392.76847V410.885l-6.30677,2.51154c-.20091.07814-.40183.14511-.61391.21209L868.949,419.826c-2.70128.79249-6.44071-2.84639-8.62849-5.35792a6.79238,6.79238,0,0,1-.17864-8.85175,7.53018,7.53018,0,0,1,.96-.9265,6.23835,6.23835,0,0,1,2.79059-1.2279l21.108-4.15237a7.072,7.072,0,0,0,2.15433-.80372Z"
          transform="translate(-156 -140.94698)"
          fill="#fff"
        />
        <path
          d="M906.13975,328.1489h-3.6137c0-51.11056-10.52349-86.7288-20.77877-86.7288l-.90343-3.6137c9.197,0,15.06047,14.824,18.35965,27.26023C903.67651,281.926,906.13975,304.329,906.13975,328.1489Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M823.02465,315.501H819.411c0-46.80915,10.59628-75.8877,26.19933-75.8877l-.90343,3.6137C833.91428,243.227,823.02465,265.57481,823.02465,315.501Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <circle cx="748.3329" cy="188.10534" r="9.03425" fill="#3f3d56" />
        <path
          d="M808.56985,337.18315h-3.6137c0-12.95188,7.2949-23.48905,16.26165-23.48905v3.6137C814.24361,317.3078,808.56985,326.22383,808.56985,337.18315Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M837.47945,337.18315h-3.6137c0-10.95932-5.67375-19.87535-12.648-19.87535v-3.6137C830.18456,313.6941,837.47945,324.23127,837.47945,337.18315Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <circle cx="679.6726" cy="197.13959" r="3.6137" fill="#3f3d56" />
        <circle cx="650.763" cy="197.13959" r="3.6137" fill="#3f3d56" />
        <path
          d="M371.27061,216.64442,307.80454,227.392a6.42418,6.42418,0,0,1-7.27008-4.61266c-4.58533-16.54945-14.172-38.13882-4.86434-48.97662a17.80544,17.80544,0,0,1,18.75545-15.93281h0c11.33772-17.74734,30.22229-15.08269,42.98594-3.99869,12.84541,4.43234,17.6883,12.30667,15.99477,22.99248,5.54925,4.85742,8.45864,10.32871,9.07208,16.33389a23.58911,23.58911,0,0,1-6.45376,18.35077Z"
          transform="translate(-156 -140.94698)"
          fill="#2f2e41"
        />
        <path
          d="M361.40329,266.60948l-49.20045-2.89414c4.82175-11.06687,6.74782-21.72377,4.82358-31.83558h36.65915C353.60249,239.64361,357.46606,253.07369,361.40329,266.60948Z"
          transform="translate(-156 -140.94698)"
          fill="#9f616a"
        />
        <path
          d="M302.17961,721.236l-2.018,27.58763a8.78223,8.78223,0,0,0,9.29009,9.40691l14.17019-.85826a4.47947,4.47947,0,0,0,4.07413-5.55747l-7.86563-31.46157Z"
          transform="translate(-156 -140.94698)"
          fill="#e6e6e6"
        />
        <path
          d="M348.05471,720.35321l-7.86563,31.46157a4.47947,4.47947,0,0,0,4.07413,5.55747l14.17019.85826a8.78223,8.78223,0,0,0,9.29009-9.40691l-2.018-27.58763Z"
          transform="translate(-156 -140.94698)"
          fill="#e6e6e6"
        />
        <polygon
          points="210.62 584.119 191.498 582.2 172.46 378.701 165.265 581.544 146.478 580.871 114.813 299.944 235.79 299.944 210.62 584.119"
          fill="#311b92"
        />
        <circle cx="180.3231" cy="66.10711" r="36.53674" fill="#9f616a" />
        <path
          d="M377.50189,189.6c-14.82916-2.58256-29.40882-2.93714-43.58164.3386-9.02812,2.95548-19.87016,2.68316-31.92763.248V165.54779l65.0942,1.17328Z"
          transform="translate(-156 -140.94698)"
          fill="#2f2e41"
        />
        <path
          d="M360.63859,236.37015c-15.98373,6.64857-32.74357,6.37859-50.18855,0,5.07616-8.66447-2.51161-15.45044-6.407-24.197l65.13832-1.93576C364.20006,217.83574,356.84992,226.813,360.63859,236.37015Z"
          transform="translate(-156 -140.94698)"
          fill="#fff"
        />
        <rect
          x="301.94089"
          y="206.06748"
          width="1.00054"
          height="8.33965"
          transform="translate(-208.68499 167.04408) rotate(-50.19486)"
          fill="#fff"
        />
        <rect
          x="354.73467"
          y="226.55581"
          width="20.88467"
          height="1.0002"
          transform="matrix(0.537, -0.84358, 0.84358, 0.537, -178.46379, 272.23589)"
          fill="#fff"
        />
        <rect
          x="305.41177"
          y="216.61358"
          width="1.0002"
          height="20.88467"
          transform="matrix(0.84366, -0.53687, 0.53687, 0.84366, -230.07485, 58.78582)"
          fill="#fff"
        />
        <path
          d="M266.86126,373.69281l-27.97673-17.36487,23.85922-69.8789a32.96549,32.96549,0,0,1,14.40973-17.11578l8.03736-4.6532.69885,70.49475Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M387.78912,407.6312,383.65,372.17881c-.221-18.71283,2.96247-36.80463,10.55359-53.47473l3.85883-52.09461L355.615,249.24461c-.13019.865-.24084,1.72943-.365,2.5943l-19.894,9.947-19.40174-4.40943.10743-6.20239-38.58857,17.36487c12.76124,59.328,5.801,120.93756-16.14379,193.709l50.83691,13.9007.42267-24.41046,45.3067-3.23615c.46667,7.91968.84167,15.69873,1.05933,23.30536,25.56524,9.412,26.44336,9.256,49.73651-4.22021C399.41528,448.08421,392.0705,427.78953,387.78912,407.6312Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <polygon
          points="297.051 224.063 271.004 215.381 229.34 166.878 242.062 125.663 299.945 206.699 297.051 224.063"
          fill="#311b92"
        />
        <path
          d="M371.41927,402.56029a7.24568,7.24568,0,0,0,8.65511,5.459l22.11071-5.00728-3.19562-14.11319-22.11118,5.00634a7.23668,7.23668,0,0,0-5.459,8.65511Z"
          transform="translate(-156 -140.94698)"
          fill="#e6e6e6"
        />
        <path
          d="M391.30944,386.23409l12.54129,23.15316c21.09424-5.88333,39.37571-24.43091,56.91816-46.30631l-4.82357-15.43543-29.90615,7.71772Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <polygon
          points="71.79 172.661 95.866 176.96 150.897 255.207 121.662 248.328 71.79 172.661"
          fill="#e6e6e6"
        />
        <path
          d="M223.51976,312.65011a2.04777,2.04777,0,0,0-1.68024,3.17254l51.43935,78.04626a2.04112,2.04112,0,0,0,1.23887.86532l23.93321,5.631a2.04428,2.04428,0,0,0,2.14046-3.166L244.11415,316.8957a2.03243,2.03243,0,0,0-1.31282-.83611L223.90555,312.685A2.21656,2.21656,0,0,0,223.51976,312.65011Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M255.60782,368.45235l1.42965,14.40006,22.55962-2.23938a7.2354,7.2354,0,0,0-1.42917-14.40006Z"
          transform="translate(-156 -140.94698)"
          fill="#e6e6e6"
        />
        <path
          d="M235.99086,366.78436a18.6092,18.6092,0,0,0,18.58724,18.485h3.70954l2.70054-22.50545-22.36037-8.04981Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <polygon
          points="211.399 71.78 210.692 71.073 211.76 70.005 218.141 62.558 218.9 63.208 212.493 70.683 211.399 71.78"
          fill="#fff"
        />
        <rect y="616.10603" width="888" height="2" fill="#3f3d56" />
        <path
          d="M495,756.053H705V501.33662c0-13.4877-13.28516-24.4604-29.61426-24.4604H524.61426c-16.32959,0-29.61426,10.9727-29.61426,24.4604Z"
          transform="translate(-156 -140.94698)"
          fill="#e6e6e6"
        />
        <path
          d="M511,745.52H689V529.6175c0-11.43243-11.26075-20.7331-25.10161-20.7331H536.10161C522.26034,508.8844,511,518.18507,511,529.6175Z"
          transform="translate(-156 -140.94698)"
          fill="#fff"
        />
        <path
          d="M493.41357,457.053c-5.19043,0-9.41357,3.48817-9.41357,7.7753v22.44387c0,4.28712,4.22314,7.7753,9.41357,7.7753H706.58691c5.19043,0,9.41309-3.48818,9.41309-7.7753V464.82832c0-4.28713-4.22266-7.7753-9.41309-7.7753Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M537.18078,717.83565a9.22367,9.22367,0,0,0-9.19666,9.19663v0a9.22369,9.22369,0,0,0,9.19666,9.19666H554.395V717.83565Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M570.19444,717.83565H553.4518V736.229h16.74264a9.22369,9.22369,0,0,0,9.19666-9.19666v0A9.22367,9.22367,0,0,0,570.19444,717.83565Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M576.19558,720.08792a9.1437,9.1437,0,0,1,2.25227,6.00111v0a9.22369,9.22369,0,0,1-9.19666,9.19666H553.4518v.94325h16.74264a9.22369,9.22369,0,0,0,9.19666-9.19666v0A9.1765,9.1765,0,0,0,576.19558,720.08792Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M614.86141,579.30953a9.22367,9.22367,0,0,0-13.00036-.38384l0,0a9.22369,9.22369,0,0,0-.38382,13.00038l11.8078,12.52624,13.3842-12.61656Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M637.50651,603.33246l-11.4843-12.18306L612.638,603.766l11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.22366,9.22366,0,0,0,637.50651,603.33246Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M639.984,609.24419a9.14368,9.14368,0,0,1-2.8219,5.75525l0,0a9.22369,9.22369,0,0,1-13.00038-.38382L613.32438,603.119l-.68637.647,11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.17654,9.17654,0,0,0,639.984,609.24419Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M565.86141,608.30953a9.22367,9.22367,0,0,0-13.00036-.38384l0,0a9.22369,9.22369,0,0,0-.38382,13.00038l11.8078,12.52624,13.3842-12.61656Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M588.50651,632.33246l-11.4843-12.18306L563.638,632.766l11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.22366,9.22366,0,0,0,588.50651,632.33246Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M590.984,638.24419a9.14368,9.14368,0,0,1-2.8219,5.75525l0,0a9.22369,9.22369,0,0,1-13.00038-.38382L564.32438,632.119l-.68637.647,11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.17654,9.17654,0,0,0,590.984,638.24419Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M614.86141,635.30953a9.22367,9.22367,0,0,0-13.00036-.38384l0,0a9.22369,9.22369,0,0,0-.38382,13.00038l11.8078,12.52624,13.3842-12.61656Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M637.50651,659.33246l-11.4843-12.18306L612.638,659.766l11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.22366,9.22366,0,0,0,637.50651,659.33246Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M639.984,665.24419a9.14368,9.14368,0,0,1-2.8219,5.75525l0,0a9.22369,9.22369,0,0,1-13.00038-.38382L613.32438,659.119l-.68637.647,11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.17654,9.17654,0,0,0,639.984,665.24419Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M644.754,692.77653a9.22367,9.22367,0,0,0,.22582-13.00406l0,0a9.22369,9.22369,0,0,0-13.00408-.22581L619.59388,691.5058l12.77828,13.22989Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M621.008,715.7119l12.04259-11.63151L620.27234,690.8505,608.22974,702.482a9.2237,9.2237,0,0,0-.2258,13.00409l0,0A9.22367,9.22367,0,0,0,621.008,715.7119Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M615.12683,718.261a9.14377,9.14377,0,0,1-5.78912-2.75175l0,0a9.22369,9.22369,0,0,1,.2258-13.00408L620.92763,691.529l-.65529-.67846L608.22974,702.482a9.2237,9.2237,0,0,0-.2258,13.00409l0,0A9.17651,9.17651,0,0,0,615.12683,718.261Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M578.754,663.77653a9.22367,9.22367,0,0,0,.22582-13.00406l0,0a9.22369,9.22369,0,0,0-13.00408-.22581L553.59388,662.5058l12.77828,13.22989Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M555.008,686.7119l12.04259-11.63151L554.27234,661.8505,542.22974,673.482a9.2237,9.2237,0,0,0-.2258,13.00409l0,0A9.22367,9.22367,0,0,0,555.008,686.7119Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M549.12683,689.261a9.14377,9.14377,0,0,1-5.78912-2.75175l0,0a9.22369,9.22369,0,0,1,.2258-13.00408L554.92763,662.529l-.65529-.67846L542.22974,673.482a9.2237,9.2237,0,0,0-.2258,13.00409l0,0A9.17651,9.17651,0,0,0,549.12683,689.261Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M672.754,628.77653a9.22367,9.22367,0,0,0,.22582-13.00406l0,0a9.22369,9.22369,0,0,0-13.00408-.22581L647.59388,627.5058l12.77828,13.22989Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M649.008,651.7119l12.04259-11.63151L648.27234,626.8505,636.22974,638.482a9.2237,9.2237,0,0,0-.2258,13.00409l0,0A9.22367,9.22367,0,0,0,649.008,651.7119Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M643.12683,654.261a9.14377,9.14377,0,0,1-5.78912-2.75175l0,0a9.22369,9.22369,0,0,1,.2258-13.00408L648.92763,627.529l-.65529-.67846L636.22974,638.482a9.2237,9.2237,0,0,0-.2258,13.00409l0,0A9.17651,9.17651,0,0,0,643.12683,654.261Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M651.86141,701.30953a9.22367,9.22367,0,0,0-13.00036-.38384l0,0a9.22369,9.22369,0,0,0-.38382,13.00038l11.8078,12.52624,13.3842-12.61656Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M674.50651,725.33246l-11.4843-12.18306L649.638,725.766l11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.22366,9.22366,0,0,0,674.50651,725.33246Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M676.984,731.24419a9.14368,9.14368,0,0,1-2.8219,5.75525l0,0a9.22369,9.22369,0,0,1-13.00038-.38382L650.32438,725.119l-.68637.647,11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.17654,9.17654,0,0,0,676.984,731.24419Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M586.86141,700.30953a9.22367,9.22367,0,0,0-13.00036-.38384l0,0a9.22369,9.22369,0,0,0-.38382,13.00038l11.8078,12.52624,13.3842-12.61656Z"
          transform="translate(-156 -140.94698)"
          fill="#3f3d56"
        />
        <path
          d="M609.50651,724.33246l-11.4843-12.18306L584.638,724.766l11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.22366,9.22366,0,0,0,609.50651,724.33246Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M611.984,730.24419a9.14368,9.14368,0,0,1-2.8219,5.75525l0,0a9.22369,9.22369,0,0,1-13.00038-.38382L585.32438,724.119l-.68637.647,11.4843,12.18305a9.2237,9.2237,0,0,0,13.00038.38383l0,0A9.17654,9.17654,0,0,0,611.984,730.24419Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M605.15789,667.31618A13.26311,13.26311,0,0,1,594.54737,680.314a13.39494,13.39494,0,0,1-5.30526,0,13.26647,13.26647,0,0,1,0-25.99579,13.39692,13.39692,0,0,1,5.30526,0A13.26319,13.26319,0,0,1,605.15789,667.31618Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M594.54737,654.31826V680.314a13.39494,13.39494,0,0,1-5.30526,0V654.31826a13.39692,13.39692,0,0,1,5.30526,0Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M611.15789,614.31618A13.26311,13.26311,0,0,1,600.54737,627.314a13.39494,13.39494,0,0,1-5.30526,0,13.26647,13.26647,0,0,1,0-25.99579,13.39692,13.39692,0,0,1,5.30526,0A13.26319,13.26319,0,0,1,611.15789,614.31618Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M600.54737,601.31826V627.314a13.39494,13.39494,0,0,1-5.30526,0V601.31826a13.39692,13.39692,0,0,1,5.30526,0Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M611.15789,690.31618A13.26311,13.26311,0,0,1,600.54737,703.314a13.39494,13.39494,0,0,1-5.30526,0,13.26647,13.26647,0,0,1,0-25.99579,13.39692,13.39692,0,0,1,5.30526,0A13.26319,13.26319,0,0,1,611.15789,690.31618Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M600.54737,677.31826V703.314a13.39494,13.39494,0,0,1-5.30526,0V677.31826a13.39692,13.39692,0,0,1,5.30526,0Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M644.15789,727.31618A13.26311,13.26311,0,0,1,633.54737,740.314a13.39494,13.39494,0,0,1-5.30526,0,13.26647,13.26647,0,0,1,0-25.99579,13.39692,13.39692,0,0,1,5.30526,0A13.26319,13.26319,0,0,1,644.15789,727.31618Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M633.54737,714.31826V740.314a13.39494,13.39494,0,0,1-5.30526,0V714.31826a13.39692,13.39692,0,0,1,5.30526,0Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M673.30955,659.24039a13.26311,13.26311,0,0,1,1.995,16.65977,13.3949,13.3949,0,0,1-3.68171,3.81979,13.26649,13.26649,0,0,1-18.717-18.04042,13.397,13.397,0,0,1,3.68172-3.81978A13.26318,13.26318,0,0,1,673.30955,659.24039Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M656.58762,657.85975l18.717,18.04041a13.3949,13.3949,0,0,1-3.68171,3.81979l-18.717-18.04042a13.397,13.397,0,0,1,3.68172-3.81978Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M677.30955,688.24039a13.26311,13.26311,0,0,1,1.995,16.65977,13.3949,13.3949,0,0,1-3.68171,3.81979,13.26649,13.26649,0,0,1-18.717-18.04042,13.397,13.397,0,0,1,3.68172-3.81978A13.26318,13.26318,0,0,1,677.30955,688.24039Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M660.58762,686.85975l18.717,18.04041a13.3949,13.3949,0,0,1-3.68171,3.81979l-18.717-18.04042a13.397,13.397,0,0,1,3.68172-3.81978Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <path
          d="M558.30955,695.24039a13.26311,13.26311,0,0,1,1.995,16.65977,13.3949,13.3949,0,0,1-3.68171,3.81979,13.26649,13.26649,0,0,1-18.717-18.04042,13.397,13.397,0,0,1,3.68172-3.81978A13.26318,13.26318,0,0,1,558.30955,695.24039Z"
          transform="translate(-156 -140.94698)"
          fill="#311b92"
        />
        <path
          d="M541.58762,693.85975l18.717,18.04041a13.3949,13.3949,0,0,1-3.68171,3.81979l-18.717-18.04042a13.397,13.397,0,0,1,3.68172-3.81978Z"
          transform="translate(-156 -140.94698)"
          opacity="0.2"
        />
        <rect x="354.5" y="316.10603" width="5" height="37" opacity="0.2" />
        <rect x="383.5" y="316.10603" width="5" height="37" opacity="0.2" />
        <rect x="412.5" y="316.10603" width="5" height="37" opacity="0.2" />
        <rect x="441.5" y="316.10603" width="5" height="37" opacity="0.2" />
        <rect x="470.5" y="316.10603" width="5" height="37" opacity="0.2" />
        <rect x="499.5" y="316.10603" width="5" height="37" opacity="0.2" />
        <rect x="528.5" y="316.10603" width="5" height="37" opacity="0.2" />
      </svg>
    </div>
  );
}

export default MainSvg;
