import React from "react";

const Maintenance = () => {
  return (
    <div>
      <figure class="text-center">
        <blockquote class="blockquote bg-blue ">
          <p>This Website is currently in Maintenance!</p>
        </blockquote>
        <figcaption class="blockquote-footer">
          Team
          <cite title="Source Title text-blue ">
            {" "}
            Medical Support Services Portal (MSSP)
          </cite>
        </figcaption>
      </figure>
    </div>
  );
};

export default Maintenance;
